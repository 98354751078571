/**
 * @file global/_functions.scss
 *
 * \brief Functions
 *
 * Useful functions to help us calculate various values
 * (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
 */

@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

///
// "Tracking" in print is equivalent to letter-spacing in CSS
// 1000 = 1em = body size
///
@function tracking($number, $font-size) {
  @return $number * $font-size / 1000;
}

// 2019 Redesign
// New line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
