.sd-product-grid,
.elc-mobile-filters-modal {
  .elc-product-grid {
    &-wrapper {
      .elc-quickview-renderer {
        clear: both;
      }
      &.has-filters {
        .elc-sort-wrapper {
          position: absolute;
          top: 0;
          #{$rdirection}: 0;
          width: calc(50% - 10px);
          @media #{$cr19-medium-up} {
            width: auto;
          }
        }
      }
      .elc-filters-content-desktop-section {
        display: none;
        @media #{$cr19-medium-up} {
          width: 100%;
          display: inline-block;
        }
      }
    }
    &--header {
      display: flex;
      margin-top: 0;
      position: relative;
      .elc-sort-wrapper {
        width: 50%;
        @include swap_direction(margin, 0 0 0 auto);
        [dir='rtl'] {
          @include swap_direction(margin, 0 auto 0 0);
        }
        @media #{$cr19-medium-up} {
          width: auto;
        }
        .elc-dropdown-options-list {
          #{$ldirection}: auto;
          min-width: 240px;
        }
        .elc-product-sorting-dropdown {
          margin-#{$ldirection}: auto;
        }
        label {
          width: 100%;
        }
        select {
          @include text-body-text;
          background-image: linear-gradient(45deg, transparent 50%, $cr19-text 50%),
            linear-gradient(135deg, $cr19-text 50%, transparent 50%);
          background-position: #{$rdirection} 20px center, #{$rdirection} 15px center;
          background-size: 5px 5px, 5px 5px, 1px 1.5em;
          background-repeat: no-repeat;
          border: 1px solid $color-black;
          color: $cr19-text;
          height: 45px;
          padding-#{$rdirection}: 35px;
          text-align-last: center;
          width: 100%;
          font-weight: bold;
          background-color: $cr19-bg-white;
          text-overflow: ellipsis;
          [dir='rtl'] & {
            background-position: #{$rdirection} 15px center, #{$rdirection} 20px center;
          }
        }
      }
      .elc-orderable-filters-wrapper {
        width: 100%;
      }
      .elc-dropdown-option {
        @include text-link;
        @include text-link--style-2;
        flex-direction: row;
        &:first-child {
          border-bottom: 0.01em solid $cr19-border-light;
        }
      }
      .elc-dropdown {
        width: 240px;
        background: transparent;
        @media #{$cr19-medium-up} {
          position: relative;
          #{$rdirection}: 0;
          min-width: 120px;
          width: auto;
        }
        @media #{$cr19-medium-portrait-only} {
          width: 270px;
        }
        &-wrapper {
          border: none;
          @media #{$cr19-medium-up} {
            height: 56px;
            padding: 0;
            gap: 25px;
          }
          .elc-dropdown-readonly-input {
            @media #{$cr19-medium-up} {
              justify-content: end;
              padding: 0;
            }
            [dir='rtl'] & {
              @media #{$cr19-medium-up} {
                justify-content: flex-start;
              }
            }
          }
          .elc-dropdown-arrow {
            #{$rdirection}: 0;
          }
        }
        &-label {
          color: $cr19-text;
          letter-spacing: -0.01em;
          line-height: get-line-height(15px, 24px);
        }
        &-arrow {
          border: none;
          color: $cr19-text;
          padding: 0;
        }
        &-arrow-icon {
          fill: $cr19-text;
        }
        &-readonly-input {
          @include text-title--small;
          color: $color-darkest-grey;
          justify-content: center;
          @media #{$cr19-xlarge-up} {
            font-size: 15px;
            font-weight: bold;
            line-height: get-line-height(17px, 24px);
            white-space: pre-wrap;
            padding-#{$rdirection}: 35px;
          }
          input {
            @include text-title--small;
          }
        }
      }
    }
    &--filters-section {
      margin-#{$rdirection}: 20px;
      width: 100%;
      .elc-overlay-wrapper-close-button {
        display: none;
      }
      .elc-product-grid--filters-modal-button {
        &:hover {
          background-color: none;
        }
      }
    }
    &--selected-filters-desktop-section {
      display: none;
      @media #{$cr19-large-up} {
        position: absolute;
        width: 56%;
        top: 15px;
        margin-#{$ldirection}: calc(25% + 20px);
        display: inline-block;
      }
      @media #{$cr19-medium-up} {
        display: inline-block;
      }
      @media #{$cr19-medium-portrait-only} {
        margin-bottom: 15px;
      }
      .elc-product-grid--selected-filter-values-wrapper,
      .elc-product-grid--clear-all {
        @media #{$cr19-medium-up} {
          display: inline;
          #{$rdirection}: auto;
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
    &--filters-desktop-section {
      @media #{$cr19-medium-up} {
        width: 100%;
        display: flex;
        height: 56px;
        align-items: center;
      }
      .elc-product-grid--filters-modal-button,
      .elc-product-grid--filter-results-count-wrapper,
      .elc-product-grid--clear-all {
        @media #{$cr19-medium-up} {
          #{$ldirection}: auto;
          #{$rdirection}: auto;
          display: inline;
          position: relative;
        }
      }
      .elc-product-grid--filter-results-count-wrapper {
        min-width: 120px;
        text-align: $rdirection;
        padding: 0;
        display: inline-flex;
        @media #{$cr19-medium-portrait-only} {
          min-width: 80px;
        }
      }
    }
    .elc-orderable-filters-wrapper {
      @media #{$cr19-medium-up} {
        position: absolute;
      }
    }
    &--filter-set {
      @include text-body-text;
      @include swap_direction(padding, 17.5px 20px 17.5px 0);
      background-color: transparent;
      border: 0;
      color: $cr19-text;
      font-weight: bold;
      height: auto;
      min-width: auto;
      width: auto;
      text-align: $ldirection;
      text-transform: none;
      @media #{$cr19-medium-up} {
        @include swap_direction(padding, 16px 20px 16px 0);
        font-size: 17px;
      }
      &::after {
        background: url('#{$base-theme-path}img/icons/src/plus-16.svg') no-repeat center;
        #{$rdirection}: 5px;
        content: '';
        display: inline-block;
        width: 13px;
        position: absolute;
        height: 54px;
        top: 0;
      }
      &.active {
        color: $cr19-text;
        &::after {
          background: url('#{$base-theme-path}img/icons/src/minus-16.svg') no-repeat center;
        }
      }
      &:hover,
      &:active {
        background-color: transparent;
        color: $cr19-text;
      }
    }
    &--filter-set-wrapper {
      width: 100%;
      position: relative;
      border-bottom: 1px solid $cr19-border-light;
      &:last-child {
        border-bottom: 0 none;
      }
      &:first-child {
        @media #{$cr19-medium-up} {
          border-top: 1px solid $cr19-border-light;
        }
        .elc-product-grid--filter-set {
          @media #{$cr19-medium-up} {
            @include swap_direction(padding, 15px 20px 15px 0);
          }
        }
      }
    }
    &--filter-set-wrapper ~ .elc-product-grid--filter-set-wrapper {
      margin: 0;
      padding: 0;
      .elc-product-grid--filter-set {
        @media #{$cr19-medium-up} {
          padding-#{$ldirection}: 0;
        }
      }
    }
    &--filter-options-wrapper {
      flex-basis: 100%;
      .elc-product-grid--filter-option {
        margin: 22px 0 0;
        padding-#{$rdirection}: 25px;
        color: $cr19-text;
        font-weight: bold;
        height: auto;
        line-height: 1;
        min-width: unset;
        letter-spacing: initial;
        width: 100%;
        text-align: start;
        &:first-child {
          margin: 7px 0 0;
        }
        &:last-child {
          margin: 22px 0;
        }
        &:hover {
          background-color: $color-grey-light;
        }
        &.button--dark {
          color: $cr19-text-black;
          border: 1.5px solid $cr19-text-black;
          background-color: $cr19-bg-white;
        }
        &-checkbox {
          display: flex;
          @media #{$cr19-medium-up} {
            display: inline-table;
          }
          &:hover {
            background-color: transparent;
          }
          .elc-checkbox-check-area {
            top: 4px;
            @media #{$cr19-medium-up} {
              top: 6px;
            }
            .elc-icon {
              @include svg-icon-inline-mask('checkbox-unselected.svg');
              height: 16px;
              width: 16px;
              transform: translate(0, 4px);
            }
          }
          .elc-checkbox-selected {
            .elc-icon {
              @include svg-icon-inline-mask('checkbox-selected.svg');
              transform: translate(-4px, 0);
            }
          }
          .elc-checkbox-label {
            @include text-body-text;
            margin-#{$ldirection}: 12px;
          }
        }
        .elc-checkbox {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding: 0;
        }
        &.no-results {
          color: $cr19-text-grey;
          cursor: not-allowed;
        }
      }
    }
    .elc-mobile-apply-filter-button,
    &--clear-all,
    &--selected-filter-value {
      background-color: transparent;
      border: 0;
      color: $cr19-text;
      letter-spacing: initial;
      font-weight: normal;
      height: auto;
      line-height: 1;
      margin-#{$rdirection}: 23px;
      min-width: auto;
      padding: 0;
      text-transform: capitalize;
      width: auto;
      @media #{$cr19-medium-up} {
        margin-#{$rdirection}: 24px;
      }
      .elc-remove-icon {
        display: inline-block;
        height: 10px;
        margin-#{$ldirection}: 9px;
        @media #{$cr19-medium-up} {
          margin-#{$ldirection}: 8px;
        }
      }
    }
    &--clear-all {
      @include text-body-text;
      align-items: center;
      background-color: transparent;
      border-radius: unset;
      bottom: 0;
      color: $cr19-text-dark;
      font-size: 13px;
      width: auto;
      z-index: 1;
      position: relative;
      display: inline-block;
      text-decoration: underline;
      text-underline-offset: 3px;
      &::after {
        width: 100%;
      }
      @media #{$cr19-medium-up} {
        color: $cr19-text;
        font-size: 13px;
      }
      &:hover {
        background-color: $cr19-bg-white;
      }
      .elc-remove-icon {
        display: none;
      }
      & ~ .elc-product-grid--filter-results-count-wrapper {
        @media #{$cr19-medium-up} {
          display: flex;
        }
      }
    }
    &--selected-filter-values-wrapper {
      display: inline;
      flex: 1;
      padding: 12px 0;
      .elc-product-grid--selected-filter-value {
        color: $cr19-text-grey;
        font-size: 13px;
        line-height: 2;
        margin-#{$rdirection}: 23px;
        &:hover {
          background-color: transparent;
        }
        .elc-remove-icon {
          height: 8.8px;
          width: 8.8px;
        }
      }
    }
    &--filter-results-count-wrapper {
      display: inline-block;
      position: relative;
      width: auto;
      @media #{$cr19-medium-up} {
        justify-content: flex-end;
        #{$ldirection}: 15%;
        position: absolute;
      }
      .elc-filter-results-count-label {
        border-#{$rdirection}: 1px solid $color-lightest-grey;
        font-size: 13px;
        width: auto;
        padding-#{$rdirection}: 14px;
        margin-#{$rdirection}: 14px;
        @media #{$cr19-medium-up} {
          border: none;
          padding: 0;
          height: auto;
          margin: 0;
        }
      }
    }
    &--filters-modal-button {
      @include text-body-text;
      align-items: center;
      border-radius: unset;
      border: 1px solid $color-black;
      color: $cr19-text;
      display: flex;
      font-weight: normal;
      justify-content: center;
      margin-#{$rdirection}: 50%;
      text-transform: capitalize;
      height: 45px;
      width: calc(50% - 10px);
      @media #{$cr19-medium-up} {
        border: none;
        justify-content: flex-start;
        margin-#{$rdirection}: 0;
        width: 100%;
        min-width: auto;
        text-align: $ldirection;
      }
      &:hover {
        background-color: transparent;
      }
      .elc-filters-label,
      .elc-applied-filters-number {
        text-transform: none;
        padding-#{$rdirection}: 3px;
        @media #{$cr19-medium-up} {
          font-size: 17px;
          padding-#{$rdirection}: 0;
        }
      }
      .elc-filter-icon {
        @include swap_direction(margin, -3px 0 0 5px);
        @media #{$cr19-medium-up} {
          display: none;
        }
      }
    }
  }
  .has-filters {
    .elc-product-grid--header {
      @media #{$cr19-medium-up} {
        width: 25%;
        display: inline-block;
        position: initial;
        padding-#{$rdirection}: 5px;
      }
      @media #{$cr19-medium-portrait-only} {
        width: 30%;
        padding: 0;
      }
    }
  }
  &.is-open {
    .elc-product-grid--clear-all {
      @include text-body-text;
      @include swap_direction(padding, 20px 79px 20px 20px);
      position: fixed;
      color: $cr19-text-dark;
      bottom: 0;
      z-index: 1;
      background-color: $cr19-bg-white;
      text-decoration: underline;
      text-underline-offset: 5px;
      border-bottom: 15px solid $cr19-bg-white;
      #{$ldirection}: 0;
    }
  }
}

.elc-responsive-modal-mask .elc-mobile-filters-modal {
  #{$rdirection}: 0;
  #{$ldirection}: auto;
  max-width: 100%;
  min-height: 100%;
  min-width: 310px;
  top: 0;
  transform: none;
  padding: 0 20px;
  [dir='rtl'] & {
    .elc-filter-by-label {
      text-align: #{$ldirection};
    }
    .elc-product-grid--filter-set {
      display: block;
    }
  }
  .elc-filter-by-label {
    @include text-title--small;
    color: $cr19-text;
    font-size: 17px;
    font-weight: bold;
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid $cr19-border-light;
    width: 100vw;
    position: relative;
    margin-#{$ldirection}: -50vw;
    #{$ldirection}: 50%;
    padding-#{$ldirection}: 20px;
    @media #{$cr19-medium-up} {
      height: 64px;
      line-height: 64px;
    }
  }
  .elc-overlay-wrapper-close-button {
    background: none;
    border: none;
    height: auto;
    outline: none;
    padding: 0;
    position: absolute;
    #{$rdirection}: 25px;
    top: 22px;
    z-index: 1;
    width: 15px;
    .elc-remove-icon {
      height: 15px;
      width: 15px;
    }
  }
  .elc-product-grid--apply-filter {
    @include text-body-text;
    border-radius: 0;
    font-weight: normal;
    padding: 15px;
    text-transform: capitalize;
    position: fixed;
    width: 60%;
    height: 56px;
    bottom: 0;
    #{$rdirection}: 22px;
    z-index: 1;
    box-shadow: 0 15px 0 0 $cr19-bg-white;
    margin-bottom: 15px;
    @media #{$cr19-medium-up} {
      width: 320px;
      height: 64px;
    }
    .elc-remove-icon {
      display: none;
    }
    &-label {
      @include text-title--small;
      color: $cr19-text-white;
      text-transform: capitalize;
    }
  }
  .elc-mobile-filters-cta {
    #{$ldirection}: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    position: fixed;
    width: 100%;
  }
}
