$base-theme-path: '/sites/clinique/themes/cl_base/';
$netstorage-font-path: '/_fonts/clinique/'; // Netstorage path.

//Template sharing POC fixes 
//compare_chart_formatter_v1.scss from EL
$max-width: 1366px;
$rdirection: right !default;
$ldirection: left !default;
$akzidenz: 'AkzidenzGrotesk' !default;
$optimalight: 'OptimaDisplayLight' !default;
$optimaregular: 'Optima LT W02 Roman' !default;
$color-white: #ffffff; // styleguide pg.5
$color-navy: #040a2b; // syleguide pg.5
$medium-max: 1024px;
$large-min: 1025px;

//content_block_tout_v1.scss from EL
$color-periwinkle-blue--aerin: #8f92a4;
$color-periwinkle-blue--aerin-v2: #4c4e59;
$color-black: #000000; // assumed from comps
$color-gold--renutriv: #c3a36e;
$color-gold--sd-renutriv: #c3a36d;
$color-gold--renutriv-v2: #ebd79a;
$akzidenzBQBOL: 'AkzidenzGroteskBQBOL';

//content_formatter_v1.scss from EL




// General - Does not generate style
@import 'global/global';

